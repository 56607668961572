<template>
  <form class="register-form">
    <BaseInput
      ref="username"
      v-model="formRegister.username"
      :label="FORM_AUTH.LABEL_USERNAME"
      placeholder=" "
      required
      :min-length="$config.MIN_LENGTH_USER_NAME"
      :max-length="$config.MAX_LENGTH_USER_NAME"
      :limit-input="$config.MAX_LENGTH_USER_NAME"
      special-character
      :error-special-character="FORM_AUTH.VALIDATE_USERNAME"
      :is-show-error-custom="isNameExists"
      @update:model-value="isNameExists = false"
      @keyup.enter="password?.focus()"
    >
      <template v-if="isNameExists" #error>
        <div class="error">
          {{ FORM_AUTH.TEXT_EXIST }}
        </div>
      </template>
    </BaseInput>
    <BaseInput
      ref="password"
      v-model.trim="formRegister.password"
      :label="FORM_AUTH.LABEL_PASSWORD"
      type="password"
      placeholder=" "
      :allow-validate-password="true"
      required
      :min-length="$config.MIN_LENGTH_PASSWORD"
      :max-length="$config.MAX_LENGTH_PASSWORD"
      :limit-input="$config.MAX_LENGTH_PASSWORD"
      @keyup.enter="phone?.focus()"
    />
    <BaseInput
      ref="phone"
      v-model="formRegister.phone"
      :label="FORM_AUTH.LABEL_PHONE_NUMBER"
      type="tel"
      placeholder=" "
      required
      :max-length="10"
      :min-length="10"
      :limit-input="10"
      phone-character
      :error-phone-character="FORM_AUTH.VALIDATE_PHONE"
      start-zero
    />
    <BaseButton class="base-button--btn-primary" :is-loading="isLoading" type="submit" @click="submit">
      {{ FORM_AUTH.REGISTER }}
    </BaseButton>
    <div class="redirect">
      Bạn đã có tài khoản?
      <span @click="openLoginPopup">Đăng Nhập</span>
    </div>
  </form>
</template>
<script setup lang="ts">
import { useRegister } from '~/composables/user/useRegister.js'
import { FORM_AUTH } from '~/constants/form/auth'
import BaseInput from '~/components/common/base-input.vue'
import BaseButton from '~/components/common/base-button.vue'
const { $config } = useNuxtApp()
const props = withDefaults(
  defineProps<{
    tabActive?: string
    isShowModal?: boolean
  }>(),
  {
    tabActive: '',
    isShowModal: false
  }
)
const { isAgree, isNameExists, username, password, phone, formRegister, isLoading, submit } = useRegister()

const openLoginPopup = () => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: 'login' }
  })
}

const route = useRoute()

watch(route, () => {
  username?.value?.resetValidate()
  password?.value?.resetValidate()
  phone?.value?.resetValidate()
})

watch(username, () => {
  isNameExists.value = false
})
</script>
<style lang="scss" scoped src="assets/scss/components/common/form/register.scss" />
